import { SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> & {
  title?: string
}

export default function EthereumLogo({ title, ...props }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      {title !== undefined && <title>{title}</title>}
      <path
        fill="currentColor"
        d="M12.628 2.556A.55.55 0 0012.083 2a.55.55 0 00-.545.556.55.55 0 01-.544.555.55.55 0 00-.545.556.55.55 0 01-.545.555.55.55 0 00-.545.556v1.11a.55.55 0 01-.545.556A.55.55 0 008.27 7v1.111a.55.55 0 01-.545.556.55.55 0 00-.545.555v1.111a.55.55 0 01-.544.556.55.55 0 00-.545.555.55.55 0 00.545.556c.3 0 .544.249.544.556a.55.55 0 00.545.555c.301 0 .545.249.545.556a.55.55 0 00.545.555h1.09c.3 0 .545.249.545.556a.55.55 0 00.545.555c.3 0 .544.25.544.556a.55.55 0 00.545.555.55.55 0 00.545-.555.55.55 0 01.545-.556.55.55 0 00.545-.555.55.55 0 01.545-.556h1.09a.55.55 0 00.544-.555.55.55 0 01.545-.556.55.55 0 00.545-.555.55.55 0 01.545-.556.55.55 0 00.545-.556.55.55 0 00-.545-.555.55.55 0 01-.545-.556v-1.11a.55.55 0 00-.545-.556.55.55 0 01-.545-.556V7a.55.55 0 00-.545-.556.55.55 0 01-.544-.555V4.778a.55.55 0 00-.545-.556.55.55 0 01-.545-.555.55.55 0 00-.545-.556.55.55 0 01-.545-.555z"
      ></path>
      <path
        fill="currentColor"
        d="M10.994 17.556a.55.55 0 01-.545-.556.55.55 0 00-.545-.556.55.55 0 01-.545-.555.55.55 0 00-.545-.556H7.18v-.444a.66.66 0 00-.653-.667H6.09v-.555a.55.55 0 00-.545-.556.55.55 0 00-.545.556.55.55 0 00.545.555h.545v.445a.66.66 0 00.654.666h.435V17a.55.55 0 00.545.556c.301 0 .545.248.545.555a.55.55 0 00.545.556c.301 0 .545.248.545.555a.55.55 0 00.545.556c.3 0 .545.248.545.555a.55.55 0 00.545.556c.3 0 .544.249.544.555a.55.55 0 00.545.556.55.55 0 00.545-.556.55.55 0 01.545-.555.55.55 0 00.545-.556.55.55 0 01.545-.555.55.55 0 00.545-.556.55.55 0 01.544-.555.55.55 0 00.545-.556.55.55 0 01.545-.555.55.55 0 00.545-.556v-1.667h.436a.66.66 0 00.654-.666v-.445h.545a.55.55 0 00.545-.555.55.55 0 00-.545-.556.55.55 0 00-.545.556v.555h-.436a.66.66 0 00-.654.667v.444h-1.634a.55.55 0 00-.545.556.55.55 0 01-.545.555.55.55 0 00-.545.556.55.55 0 01-.545.556h-2.18z"
      ></path>
    </svg>
  )
}
