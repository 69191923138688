import { SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> & {
  title?: string
}

export default function ZoraLogo({ title, ...props }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title !== undefined && <title>{title}</title>}
      <g clipPath="url(#clip0_246_2975)">
        <mask
          id="mask0_246_2975"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_246_2975)">
          <path
            d="M23.3889 -4.34766H-3.08606V22.1273H23.3889V-4.34766Z"
            fill="#A1723A"
          />
          <g filter="url(#filter0_f_246_2975)">
            <path
              d="M10.7637 19.5799C16.6571 19.5799 21.4347 14.8038 21.4347 8.91204C21.4347 3.02032 16.6571 -1.75586 10.7637 -1.75586C4.87028 -1.75586 0.0927124 3.02032 0.0927124 8.91204C0.0927124 14.8038 4.87028 19.5799 10.7637 19.5799Z"
              fill="#531002"
            />
          </g>
          <g filter="url(#filter1_f_246_2975)">
            <path
              d="M11.9138 16.2037C16.6938 16.2037 20.5687 12.3274 20.5687 7.5457C20.5687 2.76402 16.6938 -1.1123 11.9138 -1.1123C7.13385 -1.1123 3.25891 2.76402 3.25891 7.5457C3.25891 12.3274 7.13385 16.2037 11.9138 16.2037Z"
              fill="#2B5DF0"
            />
          </g>
          <g filter="url(#filter2_f_246_2975)">
            <path
              d="M11.7563 16.6975C16.7395 16.6975 20.7792 12.6564 20.7792 7.67148C20.7792 2.68657 16.7395 -1.35449 11.7563 -1.35449C6.77308 -1.35449 2.7334 2.68657 2.7334 7.67148C2.7334 12.6564 6.77308 16.6975 11.7563 16.6975Z"
              fill="url(#paint0_radial_246_2975)"
            />
          </g>
          <g filter="url(#filter3_f_246_2975)">
            <path
              d="M13.2527 9.94345C15.7631 9.94345 17.7981 7.90838 17.7981 5.39799C17.7981 2.88761 15.7631 0.852539 13.2527 0.852539C10.7423 0.852539 8.70721 2.88761 8.70721 5.39799C8.70721 7.90838 10.7423 9.94345 13.2527 9.94345Z"
              fill="#FCB8D4"
            />
          </g>
          <g filter="url(#filter4_f_246_2975)">
            <path
              d="M13.2497 7.21123C14.2521 7.21123 15.0647 6.39859 15.0647 5.39614C15.0647 4.3937 14.2521 3.58105 13.2497 3.58105C12.2472 3.58105 11.4346 4.3937 11.4346 5.39614C11.4346 6.39859 12.2472 7.21123 13.2497 7.21123Z"
              fill="white"
            />
          </g>
          <g filter="url(#filter5_f_246_2975)">
            <path
              d="M12.0437 23.5196C21.052 23.5196 28.3547 16.2169 28.3547 7.20853C28.3547 -1.79982 21.052 -9.10254 12.0437 -9.10254C3.03532 -9.10254 -4.2674 -1.79982 -4.2674 7.20853C-4.2674 16.2169 3.03532 23.5196 12.0437 23.5196Z"
              fill="url(#paint1_radial_246_2975)"
              fillOpacity="0.9"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_246_2975"
          x="-3.90729"
          y="-5.75586"
          width="29.342"
          height="29.3359"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_246_2975"
          />
        </filter>
        <filter
          id="filter1_f_246_2975"
          x="-4.74109"
          y="-9.1123"
          width="33.3098"
          height="33.3164"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4"
            result="effect1_foregroundBlur_246_2975"
          />
        </filter>
        <filter
          id="filter2_f_246_2975"
          x="-0.266602"
          y="-4.35449"
          width="24.0458"
          height="24.0518"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.5"
            result="effect1_foregroundBlur_246_2975"
          />
        </filter>
        <filter
          id="filter3_f_246_2975"
          x="2.70721"
          y="-5.14746"
          width="21.0909"
          height="21.0908"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_246_2975"
          />
        </filter>
        <filter
          id="filter4_f_246_2975"
          x="7.43457"
          y="-0.418945"
          width="11.6302"
          height="11.6299"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_246_2975"
          />
        </filter>
        <filter
          id="filter5_f_246_2975"
          x="-7.2674"
          y="-12.1025"
          width="38.6221"
          height="38.6221"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.5"
            result="effect1_foregroundBlur_246_2975"
          />
        </filter>
        <radialGradient
          id="paint0_radial_246_2975"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.2782 5.12509) rotate(128.228) scale(17.0408 17.0394)"
        >
          <stop offset="0.286458" stopColor="#387AFA" />
          <stop offset="0.647782" stopColor="#387AFA" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_246_2975"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.0437 7.20853) rotate(90) scale(16.3111)"
        >
          <stop offset="0.598958" stopOpacity="0" />
          <stop offset="0.671875" />
          <stop offset="0.734375" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_246_2975">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
