import { SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> & {
  title?: string
}

export default function ZoraLogoMono({ title, ...props }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title !== undefined && <title>{title}</title>}
      <g clipPath="url(#clip0_246_2872)">
        <mask
          id="mask0_246_2872"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path
            d="M9.99998 19.4071C15.1954 19.4071 19.4071 15.1953 19.4071 9.99992C19.4071 4.80449 15.1954 0.592773 9.99998 0.592773C4.80456 0.592773 0.592834 4.80449 0.592834 9.99992C0.592834 15.1953 4.80456 19.4071 9.99998 19.4071Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_246_2872)">
          <path
            d="M22.5948 -3.5H-2.31055V21.4054H22.5948V-3.5Z"
            fill="#525252"
          />
          <g filter="url(#filter0_f_246_2872)">
            <path
              d="M10.7182 19.0104C16.2622 19.0104 20.7565 14.5173 20.7565 8.9749C20.7565 3.43248 16.2622 -1.06055 10.7182 -1.06055C5.17414 -1.06055 0.67981 3.43248 0.67981 8.9749C0.67981 14.5173 5.17414 19.0104 10.7182 19.0104Z"
              fill="#404040"
            />
          </g>
          <g filter="url(#filter1_f_246_2872)">
            <path
              d="M11.7998 15.8304C16.2964 15.8304 19.9416 12.1839 19.9416 7.68573C19.9416 3.18753 16.2964 -0.458984 11.7998 -0.458984C7.30323 -0.458984 3.65802 3.18753 3.65802 7.68573C3.65802 12.1839 7.30323 15.8304 11.7998 15.8304Z"
              fill="#707070"
            />
          </g>
          <g filter="url(#filter2_f_246_2872)">
            <path
              d="M11.652 16.2972C16.3397 16.2972 20.1399 12.4957 20.1399 7.80629C20.1399 3.11692 16.3397 -0.68457 11.652 -0.68457C6.96419 -0.68457 3.164 3.11692 3.164 7.80629C3.164 12.4957 6.96419 16.2972 11.652 16.2972Z"
              fill="#262626"
            />
          </g>
          <g filter="url(#filter3_f_246_2872)">
            <path
              d="M13.0598 9.94648C15.4213 9.94648 17.3358 8.03206 17.3358 5.67051C17.3358 3.30895 15.4213 1.39453 13.0598 1.39453C10.6982 1.39453 8.78381 3.30895 8.78381 5.67051C8.78381 8.03206 10.6982 9.94648 13.0598 9.94648Z"
              fill="#E5E5E5"
            />
          </g>
          <g filter="url(#filter4_f_246_2872)">
            <path
              d="M13.0568 7.38078C13.9999 7.38078 14.7643 6.61632 14.7643 5.6733C14.7643 4.73029 13.9999 3.96582 13.0568 3.96582C12.1138 3.96582 11.3494 4.73029 11.3494 5.6733C11.3494 6.61632 12.1138 7.38078 13.0568 7.38078Z"
              fill="white"
            />
          </g>
          <g filter="url(#filter5_f_246_2872)">
            <path
              d="M11.9225 22.7164C20.3968 22.7164 27.2665 15.8467 27.2665 7.37238C27.2665 -1.10191 20.3968 -7.97168 11.9225 -7.97168C3.4482 -7.97168 -3.42157 -1.10191 -3.42157 7.37238C-3.42157 15.8467 3.4482 22.7164 11.9225 22.7164Z"
              fill="url(#paint0_radial_246_2872)"
              fillOpacity="0.9"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_246_2872"
          x="-3.32019"
          y="-5.06055"
          width="28.0767"
          height="28.0713"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_246_2872"
          />
        </filter>
        <filter
          id="filter1_f_246_2872"
          x="-4.34198"
          y="-8.45898"
          width="32.2836"
          height="32.2891"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4"
            result="effect1_foregroundBlur_246_2872"
          />
        </filter>
        <filter
          id="filter2_f_246_2872"
          x="0.164001"
          y="-3.68457"
          width="22.9759"
          height="22.9814"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.5"
            result="effect1_foregroundBlur_246_2872"
          />
        </filter>
        <filter
          id="filter3_f_246_2872"
          x="2.78381"
          y="-4.60547"
          width="20.5519"
          height="20.5518"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_246_2872"
          />
        </filter>
        <filter
          id="filter4_f_246_2872"
          x="7.34937"
          y="-0.0341797"
          width="11.415"
          height="11.415"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_246_2872"
          />
        </filter>
        <filter
          id="filter5_f_246_2872"
          x="-6.42157"
          y="-10.9717"
          width="36.6881"
          height="36.6885"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.5"
            result="effect1_foregroundBlur_246_2872"
          />
        </filter>
        <radialGradient
          id="paint0_radial_246_2872"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.9225 7.37238) rotate(90) scale(15.3441)"
        >
          <stop offset="0.598958" stopOpacity="0" />
          <stop offset="0.671875" />
          <stop offset="0.734375" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_246_2872">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
